import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Header from "../template/Header";
import Footer from "../template/Footer";

import Close from "../../assets/icons/close.svg";
import wallet from '../../assets/icons/wallet 1.png'
import wallet2 from '../../assets/icons/wallet 1 (1).png'
import edit from '../../assets/icons/newEdit.png';
import add from '../../assets/icons/add (1) 1.png';
import reload from '../../assets/icons/reload 1.png'
import ledgerBalance from '../../assets/icons/Ledger_Balance_icon.png'
import useToast from "../Hooks/Toast";
import useToggleVisibility from '../Hooks/useActionVisibility';
import { BsThreeDotsVertical } from "react-icons/bs";
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddAdvertiserModal from './AddAdvertiserModal';
import AddBudgetModal from './AddBudgetModal';
import AddExpenseModal from './AddExpenseModal';
import useGetBudgetDetail from './useGetBudgetDetail.js'
import { cookieGetter } from '../utils/HelperFunction.js';
import LedgerBalance from './LedgerBalance.js';


const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 20,
    borderRadius: 15,
    backgroundColor: '#e0e0e0',
    '& .MuiLinearProgress-bar': {
        borderRadius: 5,
        backgroundColor: value > 80 ? '#f44336' : '#1C4645'
    },
}));

const Budget = () => {
    const role = cookieGetter('role')

    const { data, hovered, loading, setHovered, handleSearch, searchInput, setSearchInput } = useGetBudgetDetail();
    const [selectedMonth, setSelectedMonth] = useState(null); // Initialize with the current month in YYYY-MM format
    const [openAdvertiserModal, setOpenAdvertiserModal] = useState(false);
    const [openBudgetModal, setOpenBudgetModal] = useState({});
    const [openExpenseModal, setOpenExpenseModal] = useState({});
    const [openLedgerBalanceModal, setOpenLedgerBalanceModal] = useState({});
    const [initialData, setInitialData] = useState(null)
    const { isVisible, handleButtonClick, elementRef, buttonRef } = useToggleVisibility();
    const [showRefreshButton, setShowRefreshButton] = useState(false);


    const notify = useToast();

    const handleAdvertiserRedirect = () => {
        if (role !== "SUPER_ADMIN") {
            notify(`You don't have permission to access this page`, 'error');
            return;
        }
        setOpenAdvertiserModal(true);
        setInitialData()
    };
    const handleBudgetRedirect = (budgetData) => {
        // console.log("user", role)
        if (role !== "SUPER_ADMIN") {
            notify(`You don't have permission to access this page`, 'error');
            return;
        }
        setOpenBudgetModal({ open: true, data: budgetData });
    };
    const handleExpenseRedirect = (expenseData) => {
        setOpenExpenseModal({ open: true, data: expenseData });
    };
    const handleLedgerBalanceRedirect = (budgetData) => {
        if (role !== "SUPER_ADMIN") {
            notify(`You don't have permission to access this page`, 'error');
            return;
        }
        setOpenLedgerBalanceModal({ open: true, data: budgetData });
    };

    const handleCloseModal = () => {
        setOpenAdvertiserModal(false); // Close modal
    };
    const handleCloseBudgetModal = () => {
        setOpenBudgetModal({ open: false, data: null });
    };
    const handleCloseExpenseModal = () => {
        setOpenExpenseModal({ open: false, data: null });
    };
    const handleCloseLedgerBalanceModal = () => {
        setOpenLedgerBalanceModal({ open: false, data: null });
        setSelectedMonth(null)
    };

    const handleEditt = (dt) => {
        setOpenAdvertiserModal(true);
        setInitialData(dt);
    };

    const sanitizeValue = (input) => {
        if (typeof input === 'string') {
            return parseFloat(input.replace(/[^0-9.-]/g, ''));
        }
        return input;
    };
    function formatAmount(amount) {
        // Ensure amount is treated as a string
        amount = String(amount);

        // Clean the amount to remove any non-numeric characters
        amount = sanitizeValue(amount);

        // Convert to number
        amount = parseFloat(amount);

        // Handle invalid or NaN values
        if (isNaN(amount) || amount < 0) {
            return '₹0.00';
        }

        // Format the amount based on its value
        if (amount >= 1000000) {
            return `₹${(amount / 1000000).toFixed(2)}M`; // For values 1,000,000 and above
        } else if (amount >= 1000) {
            return `₹${(amount / 1000).toFixed(2)}K`; // For values 1,000 and above
        } else {
            return `₹${amount.toFixed(2)}`; // For values less than 1,000
        }
    }

    const handleRefresh = () => {
        setShowRefreshButton(false);
        window.location.reload();
    };

    useEffect(() => {
        let interval;
        if (!showRefreshButton) {
            interval = setInterval(() => {
                setShowRefreshButton(true);
            }, 300000);
        }

        return () => clearInterval(interval);
    }, [showRefreshButton]);

    const filteredData = data?.filter(dt =>
        dt.advertiserName.toLowerCase().includes(searchInput.toLowerCase()) ||
        dt.advertiserId.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <>
            <div>
                <Header />

                {showRefreshButton && (
                    <button onClick={handleRefresh} className='sticky float float-right top-24 right-0 mt-4 mr-4' title='Refresh The Page'>
                        <img src={reload} alt='Reload' className='h-10' />
                    </button>
                )}

                <div className='bg-white h-[calc(100%-198px)] pt-20 px-0 md:px-[0%] lg:px-[10%]'>
                    <div className="flex justify-space items-center py-10 max-[575px]:flex-col max-[575px]:gap-5">
                        <div className='flex gap-[10%] w-[40%] max-lg:ml-2'>
                            <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white"
                                onClick={handleAdvertiserRedirect}>
                                +&nbsp;&nbsp;Add Advertiser
                            </button>

                        </div>
                        <form className="search-box" style={{ position: 'relative' }}>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search Advertiser"
                                value={searchInput}
                                onChange={handleSearch}
                            />
                            {searchInput && (
                                <img
                                    src={Close}
                                    className="search-close"
                                    alt=""
                                    onClick={() => setSearchInput('')}
                                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                />
                            )}
                        </form>
                    </div>
                    {loading ? (
                        <div className="alert-table bg-white shadow mt-35 overlay">
                            <div className="loading-Overlay"></div>
                        </div>
                    ) :

                        <Table className='border'>
                            <TableHead sx={{ backgroundColor: '#afb3af' }}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '80px' }}></TableCell>
                                    <TableCell className='' sx={{ width: '400px', textAlign: 'center' }} >Advertiser Name</TableCell>
                                    <TableCell sx={{ width: '200px', textAlign: 'center' }}>ID</TableCell>
                                    <TableCell sx={{ width: '400px', textAlign: 'center' }}>Day View</TableCell>
                                    <TableCell sx={{ width: '400px', textAlign: 'center' }}>Month View</TableCell>
                                    <TableCell sx={{ width: '200px', textAlign: 'center' }}>Add Budget</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {filteredData?.map((dt, index) => {
                                    const monthlySpend = parseFloat(dt.monthlySpend) + parseFloat(dt.expense || 0);
                                    const dailySpend = parseFloat(dt.dailySpend);

                                    const monthlyBudget = parseFloat(dt.monthlyBudget.replace('INR ', ''));
                                    const dailyBudget = parseFloat(dt.dailyBudget.replace('INR ', ''));

                                    const expenditurePercentMonth = (monthlySpend / monthlyBudget) * 100;
                                    const expenditurePercentDay = (dailySpend / dailyBudget) * 100;

                                    const remainingBudgetMonth = monthlyBudget - monthlySpend;
                                    const remainingBudgetDay = dailyBudget - dailySpend;

                                    return (
                                        <TableRow key={index} sx={{ height: 85 }}>
                                            <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{index + 1}</TableCell>

                                            <TableCell>
                                                <div className='flex gap-2 justify-center'>
                                                    <div className={`h-2 w-2 rounded-full ${dt.status === 'Paused' ? 'bg-red-500' : 'bg-green-500'}`}></div>
                                                    {/* <img src={arrow} alt='arrow' className='cursor-pointer' /> */}
                                                </div>
                                            </TableCell>

                                            <TableCell>
                                                <div className='text-center'>{dt.advertiserName}</div>
                                            </TableCell>

                                            <TableCell sx={{
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                                color: 'inherit',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            }}>
                                                {dt.advertiserId
                                                }
                                            </TableCell>


                                            {/* {DAY COLUMN} */}


                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <div className='flex flex-col '>
                                                    <div className='w-[100%] flex justify-between text-[11px]'>
                                                        {/* <div>Expenditure</div>
                                                        <div>Remaining</div> */}
                                                    </div>
                                                    <div className='flex w-[100%]'>

                                                        <div style={{ position: 'relative', height: 20, width: '100%' }}>


                                                            <BorderLinearProgress
                                                                variant="determinate"
                                                                value={expenditurePercentDay}
                                                                className=''
                                                            />
                                                            <span style={{
                                                                position: 'absolute',
                                                                left: '5%',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                fontSize: '10px',
                                                                color: 'white',
                                                                paddingTop: '2px'
                                                            }}>
                                                                {/* ₹{(dailySpend / 100000).toFixed(2)} Lacs */}
                                                                {formatAmount(dailySpend)}
                                                            </span>

                                                            <span style={{
                                                                position: 'absolute',
                                                                right: '5%',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                color: 'black',
                                                                fontSize: '10px',
                                                                paddingTop: '2px',
                                                            }}>
                                                                {/* ₹{(remainingBudgetDay / 100000).toFixed(2)} Lacs */}
                                                                {formatAmount(remainingBudgetDay)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* Total =  */}
                                                    <div className='text-[12px] font-medium'>
                                                        {formatAmount(dailyBudget)}
                                                    </div>
                                                </div>
                                            </TableCell>

                                            {/* {MONTH COLUMN} */}

                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <div className='flex flex-col'>
                                                    <div className='w-[100%] flex justify-between text-[11px]'>
                                                        {/* <div> Expenditure </div>
                                                        <div>Remaining</div> */}
                                                    </div>
                                                    <div className='flex w-[100%]'>

                                                        <div style={{ position: 'relative', height: 20, width: '100%' }}>


                                                            <BorderLinearProgress
                                                                variant="determinate"
                                                                value={expenditurePercentMonth}
                                                                className=''
                                                            />
                                                            <span style={{
                                                                position: 'absolute',
                                                                left: '5%',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                fontSize: '10px',
                                                                color: 'white',
                                                                paddingTop: '2px'
                                                            }}>
                                                                {/* ₹{(monthlySpend / 100000).toFixed(2)} Lacs */}
                                                                {formatAmount(monthlySpend)}
                                                            </span>

                                                            <span style={{
                                                                position: 'absolute',
                                                                right: '5%',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                color: 'black',
                                                                fontSize: '10px',
                                                                paddingTop: '2px'
                                                            }}>
                                                                {/* ₹{(remainingBudgetMonth / 100000).toFixed(2)} Lacs */}
                                                                {formatAmount(remainingBudgetMonth)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* Total =  */}
                                                    <div className='text-[12px] font-medium'>
                                                        {formatAmount(monthlyBudget)}
                                                    </div>
                                                </div>
                                            </TableCell>

                                            <TableCell className="">
                                                <div className="flex justify-center">
                                                    <div className="" onClick={() => handleBudgetRedirect(dt)}>
                                                        {/* <img src={wallet} alt='wallet' onClick={()=>handleBudgetRedirect(dt)} /> */}
                                                        {/* First image */}
                                                        <img
                                                            src={wallet}
                                                            alt="wallet"
                                                            className={hovered[index] ? 'hidden' : ''}
                                                            onMouseEnter={() => {
                                                                const newHovered = [...hovered];
                                                                newHovered[index] = true;
                                                                setHovered(newHovered);
                                                            }}
                                                            onMouseLeave={() => {
                                                                const newHovered = [...hovered];
                                                                newHovered[index] = false;
                                                                setHovered(newHovered);
                                                            }}
                                                            onClick={() => handleBudgetRedirect(dt)}
                                                        />

                                                        {/* Second image */}
                                                        <img
                                                            src={wallet2}
                                                            alt="wallet2"
                                                            className={`top-0 left-0 bg-primaryColor border border-transparent rounded-full p-2 ${hovered[index] ? '' : 'hidden'
                                                                }`}
                                                            onMouseEnter={() => {
                                                                const newHovered = [...hovered];
                                                                newHovered[index] = true;
                                                                setHovered(newHovered);
                                                            }}
                                                            onMouseLeave={() => {
                                                                const newHovered = [...hovered];
                                                                newHovered[index] = false;
                                                                setHovered(newHovered);
                                                            }}
                                                            onClick={() => {
                                                                handleBudgetRedirect(dt)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center', width: '150px' }}>
                                                <button
                                                    className='action-icon'
                                                    ref={el => buttonRef.current[index] = el}
                                                    onClick={() => {
                                                        if (role !== 'SUPER_ADMIN') {
                                                            notify(`You don't have permission to make any change in details`, 'error');
                                                            return;
                                                        }
                                                        handleButtonClick(index)
                                                    }}

                                                // disabled={isVisible !== index}

                                                >
                                                    <BsThreeDotsVertical
                                                        className='pointer-cell-icon'
                                                        size={20}
                                                        color='#4d4b4b'


                                                    />
                                                </button>
                                                {isVisible === index && (
                                                    <div
                                                        ref={elementRef}
                                                        className='absolute right-20 z-50 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'
                                                    >
                                                        <div className='flex flex-col '>
                                                            <button
                                                                className='  px-3 pt-0.5 text-sm hover:bg-gray-100  hover:text-gray-900 text-left'
                                                                onClick={() => handleExpenseRedirect(dt)}
                                                            >
                                                                <img src={add} alt='add' className='inline-block ' size={20} onClick={() => handleExpenseRedirect(dt)} />
                                                                Add Expense
                                                            </button>
                                                            <button
                                                                className=' px-4 pb-2 pt-1 text-sm hover:bg-gray-100 hover:text-gray-900 text-left flex items-center'
                                                                onClick={() => { handleEditt(dt) }}
                                                            //onClick={(dt) => handleAdvertiserRedirect(dt)}
                                                            >
                                                                <img src={edit} alt='edit' className='inline-block pr-3' onClick={(dt) => handleEditt(dt)} />
                                                                Edit
                                                            </button>
                                                            <button
                                                                className=' px-2.5 py-1 text-sm hover:bg-gray-100  hover:text-gray-900 text-left flex items-center'
                                                                // onClick={() => PopUpAlert({ data: dt, value: 'config', handleAction: handleDelete, index })}
                                                                onClick={() => handleLedgerBalanceRedirect(dt)}
                                                            >
                                                                <img src={ledgerBalance} alt='speedometer' className='inline-block pr-1.5 h-8 w-11' size={20} />
                                                                Ledger Balance
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    }
                </div>
                <div className='flex justify-center py-10'>
                </div>

                <Footer />
                {/* <AddAdvertiserModal open={openAdvertiserModal} handleClose={handleCloseModal} /> */}
                <AddAdvertiserModal
                    open={openAdvertiserModal}
                    handleClose={handleCloseModal}
                    initialData={initialData}
                />
                <AddBudgetModal openBudgetModal={openBudgetModal} handleClose={handleCloseBudgetModal} />
                <AddExpenseModal openExpenseModal={openExpenseModal} handleClose={handleCloseExpenseModal} />

                <LedgerBalance openLedgerBalanceModal={openLedgerBalanceModal} handleClose={handleCloseLedgerBalanceModal} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />

            </div>

        </>
    );

}

export default Budget;
