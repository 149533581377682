import React, { useState } from "react";
import Datepicker from "./datepicker";
import Header from "../template/Header";
import AdvertiserList from "./advertiserList";
import PerformanceOverview from "./performanceOverview";
import UseDashboardData from "../Hooks/Dashboard/useDashboardData";
import DashboardChart from "./dashboardChart";
import { CircularProgress } from "@mui/material";
import useToast from "../Hooks/Toast";

const Dashboard = () => {
  const [advertiserId, setAdvertiserId] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [temporaryMetrics, setTemporaryMetrics] = useState(new Set()); // Temporary state for metrics
  const [submittedMetrics, setSubmittedMetrics] = useState(new Set()); // State for metrics after submission

  const { dashboardApiCall, loading, setLoading, dashBoardData } = UseDashboardData();
  const notify = useToast();

  const handleSubmit = async () => {
    if (!advertiserId) {
      notify("Select an advertiser", "error");
      return;
    }
    if (!selectedDateRange?.startDate && !selectedDateRange?.endDate) {
      notify("Select date range first", "error");
      return;
    }
    if (temporaryMetrics.length == 0) {
      notify("Select atleast one metric", "error");
      return;
    }

    setLoading(true);
    const finalObject = {
      startDate: selectedDateRange?.startDate,
      endDate: selectedDateRange?.endDate,
      advertiserId: advertiserId,
      selectedMetrics: Array.from(temporaryMetrics),
    };

    await dashboardApiCall(finalObject);
    setSubmittedMetrics(temporaryMetrics); // Update submitted metrics
    setLoading(false); // Reset loading state after API call
  };

  return (
    <>
      <Header />
      <div className="flex px-20">
        <div className="flex-1 flex flex-col">
          <div className="flex justify-start gap-10">
            <AdvertiserList
              advertiserId={advertiserId}
              setAdvertiserId={setAdvertiserId}
            />
            <Datepicker
              selectedDateRange={selectedDateRange}
              setselectedDateRange={setSelectedDateRange}
            />
          </div>
          <PerformanceOverview
            temporaryMetrics={temporaryMetrics} // Pass temporary metrics
            setTemporaryMetrics={setTemporaryMetrics} // Pass setter function
            selectedDateRange={selectedDateRange}
            advertiserId={advertiserId}
          />
          <div className="">
            <button
              className="mt-32 cursor-pointer font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white"
              onClick={() => handleSubmit()}
            >
              Submit
            </button>
          </div>
        </div>

        {loading ? (
          <div className="w-full flex flex-col gap-3 justify-center items-center">
            <CircularProgress
              size={40}
              color="inherit"
              sx={{ color: "#1c4546" }}
            />
            <div>Loading...</div>
          </div>
        ) : (
          <div className="w-[60%] xl:w-[50%] px-10 xl:px-20 mt-28">
            <DashboardChart
              dashBoardData={dashBoardData}
              selectedMetrics={Array.from(submittedMetrics)?.slice(0, 1)}
              loading={loading}
            />
          </div>
        )}
      </div>

      {dashBoardData?.selectedDateData?.responseDateWise && dashBoardData?.comparisionDateData?.responseDateWise ? (
        <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-5 px-20 mb-10">
          {!loading &&
            Array.from(submittedMetrics)
              ?.slice(1)
              ?.map((metric) => (
                <div key={metric} className="col-span-1">
                  <DashboardChart
                    dashBoardData={dashBoardData}
                    selectedMetrics={[metric]}
                    loading={loading}
                  />
                </div>
              ))}
        </div>
      ) : null}
    </>
  );
};

export default Dashboard;
