import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import { listOfMterix } from "../utils/constants";
import UsePerformanceOverview from "../Hooks/Dashboard/usePerformanceOverview";
import { calculatePercentageChange } from "../utils/HelperFunction";

const PerformanceOverview = ({ setTemporaryMetrics, selectedDateRange, advertiserId }) => {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const { performanceData, performanceApiCall } = UsePerformanceOverview();
  
  const [prevAdvertiserId, setPrevAdvertiserId] = useState(advertiserId);
  const [prevSelectedDateRange, setPrevSelectedDateRange] = useState(selectedDateRange);

  const performanceObject = {
    startDate: selectedDateRange?.startDate,
    endDate: selectedDateRange?.endDate,
    advertiserId: advertiserId,
  };

  useEffect(() => {
    // Check if the advertiserId or selectedDateRange has changed
    if (advertiserId && (prevAdvertiserId !== advertiserId || 
        JSON.stringify(prevSelectedDateRange) !== JSON.stringify(selectedDateRange))) {
      performanceApiCall(performanceObject);
      
      // Update the previous values
      setPrevAdvertiserId(advertiserId);
      setPrevSelectedDateRange(selectedDateRange);
    }
  }, [advertiserId, selectedDateRange, performanceApiCall, performanceObject, prevAdvertiserId, prevSelectedDateRange]);

  useEffect(() => {
    const tmpArray = [];
    listOfMterix?.forEach((val, index) => {
      if (selectedRows.has(index)) {
        tmpArray?.push(val?.key);
      }
    });
    setTemporaryMetrics(tmpArray); // Set temporary metrics directly
  }, [selectedRows, setTemporaryMetrics]);

  const handleCheckboxChange = (index) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = new Set(prevSelectedRows);
      if (updatedSelectedRows.has(index)) {
        updatedSelectedRows.delete(index);
      } else {
        updatedSelectedRows.add(index);
      }
      return updatedSelectedRows;
    });
  };

  return (
    <div className="relative top-28 flex">
      <div className="mt-[119px] xl:mt-[137px] flex flex-col">
        {listOfMterix.map((_, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedRows.has(index)}
                onChange={() => handleCheckboxChange(index)}
              />
            }
          />
        ))}
      </div>
      <div className="bg-primaryColor h-[480px] w-[300px] xl:h-[500px] xl:w-[350px] px-2 rounded-[10px]">
        <div className="text-right text-white pt-0.5 h-5">
          {selectedDateRange?.startDate
            ? `${moment(selectedDateRange?.startDate).format("D MMM YYYY")} - ${moment(selectedDateRange?.endDate).format("D MMM YYYY")}`
            : ""}
        </div>
        <div className="text-white text-center py-3 xl:py-5">PERFORMANCE OVERVIEW</div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "700" }}>
                  Metric
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "700" }}>
                  Value
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "700" }}>
                  Vs Prev
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfMterix?.map((metricItem, index) => (
                <TableRow key={index} sx={{ borderBottom: "1px solid #4b5563", minHeight: "60px" }}>
                  <TableCell sx={{ color: "white", padding: "5px", fontWeight: "500" }}>
                    {metricItem?.metric}
                  </TableCell>
                  <TableCell sx={{ color: "white", padding: "10.5px", fontWeight: "500" }}>
                    {performanceData?.selectedDateData?.[metricItem.key] ?? 'N/A'}
                  </TableCell>
                  <TableCell sx={{ color: "white", padding: "10.5px", fontWeight: "500" }}>
                    {performanceData?.selectedDateData?.[metricItem?.key] &&
                      performanceData?.comparisionDateData?.[metricItem?.key] ? (
                      <div
                        className={`font-medium flex items-center ${
                          calculatePercentageChange(
                            performanceData?.selectedDateData?.[metricItem?.key],
                            performanceData?.comparisionDateData?.[metricItem?.key]
                          ) < 0
                            ? "text-red-400"
                            : "text-green-400"
                        }`}
                      >
                        <img
                          src={`${calculatePercentageChange(
                            performanceData?.selectedDateData?.[metricItem?.key],
                            performanceData?.comparisionDateData?.[metricItem?.key]
                          ) < 0
                            ? "/red_arrow.png"
                            : "/green_arrow.png"
                          }`}
                          alt="arrow"
                          className="h-4"
                        />
                        {Math.abs(
                          calculatePercentageChange(
                            performanceData?.selectedDateData?.[metricItem?.key],
                            performanceData?.comparisionDateData?.[metricItem?.key]
                          )
                        )}
                        %
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default PerformanceOverview;
