import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { listOfMterix } from "../utils/constants";
import { calculatePercentageChange } from "../utils/HelperFunction";

const CustomTooltip = ({ active, payload, metricName }) => {
  if (active && payload && payload.length) {
    const originalDate = payload[0]?.payload?.originalDate || "N/A";
    const comparisonDate = payload[0]?.payload?.comparisonDate || "N/A";

    return (
      <div className="bg-white pt-2 px-2 border rounded shadow-md">
        <h3 className="text-black font-medium">{metricName}</h3>
        <p className="text-[#8884d8]">{`Original Date: ${originalDate}`}</p>
        <p className="text-[#8884d8]">{`Original Value: ${payload[0]?.value}`}</p>
        <p className="text-[#82ca9d]">{`Comparison Date: ${comparisonDate}`}</p>
        <p className="text-[#82ca9d]">{`Comparison Value: ${payload[1]?.value || 0}`}</p>
      </div>
    );
  }
  return null;
};

const DashboardChart = ({ dashBoardData, selectedMetrics, loading }) => {
  if (!dashBoardData) {
    return <div className="text-red-600 flex justify-center text-base font-medium w-full h-full items-center">Please select an advertiser, date range, and metric.</div>;
  }
  else if (!dashBoardData?.selectedDateData?.responseDateWise) {
    return <div className="text-red-600 flex justify-center text-base font-medium w-full h-full items-center">No data available</div>;
  }

  const charts = Array.from(selectedMetrics)?.map((metric) => {
    const chartData = dashBoardData?.selectedDateData?.responseDateWise?.map((originalItem, index) => {
      const comparisonItem = dashBoardData?.comparisionDateData?.responseDateWise[index] || {};
      return {
        originalDate: originalItem?.date,
        comparisonDate: comparisonItem?.date,
        originalMetric: originalItem[metric],
        comparisonMetric: comparisonItem[metric] || 0,
      };
    });
    const calculatedValue = calculatePercentageChange(dashBoardData?.selectedDateData?.[metric], dashBoardData?.comparisionDateData?.[metric])
    const metricChart = listOfMterix?.find((obj) => {
      return obj?.key === metric
    })?.metric;

    return (
      <div key={metric} className="mt-4 bg-[#1C4645] text-white py-3 px-5 text-center rounded-xl">
        <h3>{metricChart}</h3>
        <div className="flex pb-10 justify-around">
          <div className="text-3xl font-medium">
            {dashBoardData?.selectedDateData?.[metric]}
          </div>
          <div
            className={`text-lg font-medium flex items-center ${calculatedValue < 0 ? 'text-red-400' : 'text-green-400'}`}
          >
            <img src={`${calculatedValue < 0 ? '/red_arrow.png' : '/green_arrow.png'}`} alt="arrow-down" className="h-5" />{Math.abs(calculatedValue)}%
          </div>
        </div>
        <ResponsiveContainer height={350}>
          <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="originalDate" tick={{ fill: "white", fontWeight: "600" }} />
            <YAxis tick={{ fill: "white", fontWeight: "600" }} />
            <Tooltip content={<CustomTooltip metricName={metricChart} />} />
            <Legend wrapperStyle={{
              paddingTop: "10px",
              justifyContent: "center",
              display: "flex",
            }} />
            <Line type="monotone" dataKey="originalMetric" stroke="#8884d8" strokeWidth={4} name="Selected date" dot={false} />
            <Line type="monotone" dataKey="comparisonMetric" stroke="#82ca9d" strokeWidth={4} name="Comparison date" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  });

  return <div className="flex flex-col">{charts}</div>;
};

export default DashboardChart;

