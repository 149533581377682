import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdvertiserDetails from "../Hooks/Dashboard/useAdvertiser";
const AdvertiserList = ({ setAdvertiserId }) => {
    const {
        advertiser = [],
        getAdvertiser,
        loading,
    } = AdvertiserDetails();

    const [selectedAdvertiser, setSelectedAdvertiser] = useState("");

    const handleAdvertiser = (e) => {
        const advertiserName = e.target.value;
        setSelectedAdvertiser(advertiserName);

        const selectedAdv = advertiser.find((item) => item.name === advertiserName);
        setAdvertiserId(selectedAdv?.id);
    };

    useEffect(() => {
        getAdvertiser();
    }, []);

    useEffect(() => {
        if (advertiser.length > 0) {
          const defaultAdvertiser = advertiser[0];
          setSelectedAdvertiser(defaultAdvertiser.name);
          setAdvertiserId(defaultAdvertiser.id);
        }
      }, [advertiser]);

      
    return (
        <div className="relative top-24 min-w-40 max-h-5">
            <FormControl fullWidth>
                <InputLabel
                    id="advertiser-select-label"
                    sx={{
                        color: "white",
                        "&.Mui-focused": {
                            color: "red",
                        },
                        "&.MuiInputLabel-shrink": {
                            color: "black",
                        },
                    }}
                >
                    Select Advertiser
                </InputLabel>
                <Select
                    labelId="advertiser-select-label"
                    id="advertiser-select"
                    value={selectedAdvertiser}
                    label="Select Advertiser"
                    onChange={handleAdvertiser}
                    sx={{
                        height: "55px",
                        width: "250px",
                        backgroundColor: "#1c4546",
                        color: "white",
                        borderRadius: "10px",
                        "& .MuiSvgIcon-root": {
                            color: "white",
                        },
                    }}
                >
                    {loading ? (
                        <MenuItem value="" disabled>
                            Loading...
                        </MenuItem>
                    ) : (
                        advertiser?.map((item) => (
                            <MenuItem key={item?.id} value={item?.name}>
                                {item?.name}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </div>
    );
};
export default AdvertiserList;
