import { useState } from "react";
import { cookieGetter } from "../../utils/HelperFunction";
import axios from "axios";

const AdvertiserDetails = () => {
  const [advertiser, setAdvertiser] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAdvertiser = async () => {
    const appIdToken = cookieGetter("appIdToken");
    setLoading(true);
    try {
      const response = await axios.get(
        `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/dv360/entities?entityType=ADVERTISERS`,
        {
          headers: {
            Authorization: `Bearer ${appIdToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setAdvertiser(response?.data?.entities || []);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching ledger details:", error);
    }
  };

  return {
    advertiser,
    loading,
    setAdvertiser,
    getAdvertiser,
  };
};
export default AdvertiserDetails;
