import { useState } from 'react';
import axios from 'axios';
import useToast from '../Hooks/Toast';
import { cookieGetter } from '../utils/HelperFunction';

const useLedgerBalanceDetail = () => {
    const [data, setData] = useState([]);
    const [table3, setTable3] = useState([]);
    const [loading, setLoading] = useState(true);
    const notify = useToast();

    const getLedgerDetails = async (advertiserId, selectedMonth) => {
        const appIdToken = cookieGetter("appIdToken");
        setLoading(true);

        try {
            const response = await axios.get(
                `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/budget/laser1?advertiserId=${advertiserId}&month=${selectedMonth}`,
                {
                    headers: {
                        'Authorization': `Bearer ${appIdToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            // console.log('API Response:', response.data);

            if (response?.data && Array.isArray(response?.data?.Lasers)) {
                setData(response?.data);
                setTable3(response?.data?.Lasers);
            } else {
                console.error('Unexpected response structure:', response?.data);
                setData([]);
                setTable3([]);
            }

            // const totalResults = response.data.totalResults || 0;
            // const resultsPerPage = 10;
            // setTotalPages(Math.ceil(totalResults / resultsPerPage));

            setLoading(false);
        } catch (error) {
            console.error('Error fetching ledger details:', error);
            notify('Error fetching ledger details', 'error');
            setData([]);
            setTable3([]);
            setLoading(false);
        }
    };

    return {
        data,
        table3,
        loading,
        // totalPages,
        getLedgerDetails
    };
};

export default useLedgerBalanceDetail;